@import "../../theme/variable";
@import "../../theme/mixins";

.thumbs {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  margin: 0;
  grid-template-columns: repeat(auto-fill, 370px);
  list-style: none;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;
  //li {
  //  width: 32%;
  //}
  //.MuiGrid-item:first-child {
  //  padding-bottom: 0;
  //}
}

.Wrapper {
  position: relative;
  list-style: none;
  width: 370px;
  margin-bottom: 0.5rem;

  &.active {
    .thumb-box {
      background-image: none !important;
      background-color: rgb(230, 230, 230);
    }
  }

  &.clone {
    margin-top: 10px;
    margin-left: 10px;

    .thumb-box {
      transform: scale(1.025);
      animation: pop 150ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 6px 0 rgba(34, 33, 81, 0.3);
      cursor: grabbing;
    }
  }

  &.insertBefore,
  &.insertAfter {
    .thumb-box:after {
      left: 0;
      right: 0;
      height: 2px;
    }
  }

  &.insertBefore {
    .thumb-box:after {
      top: -15px;
    }
  }

  &.insertAfter {
    .thumb-box:after {
      bottom: -45px;
    }
  }
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;

  &-box {
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    //padding: 4px 4px 15px 4px;
    padding-left: 4px;
    padding-bottom: 15px;
    padding-right: 4px;
    margin: 8px;
    width: 100%;

    .handle {
      margin: 8px;
      width: 100%;
      position: absolute;
      top: 20px;
      z-index: 999;
      left: 10px;
    }

    .clear {
      cursor: pointer;
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 100000;
      color: red;
    }
  }
}

.thumbInner {
  display: flex;
  minWidth: 0;
  overflow: hidden
}

.thumbImage {
  display: block;
  width: 300px;
  height: 200px;
  object-fit: cover;
  @include respond-to('xxl') {
    width: 200px;
    height: 140px;
  }
}
.pageInfo {
  .thumbImage {
    height: 80px;
    width: 120px;
  }
  .MuiGrid-item {
    width: 100%;
  }
  .thumbs {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100%;
    }
  }
}