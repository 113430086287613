.list-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--header {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    
    & > * {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        user-select: none;

        td, th {
            border-bottom: none;
        }

        td:nth-of-type(1), th:nth-of-type(1) {
            width: 60px;
        }
        td:nth-of-type(2), th:nth-of-type(2) {
            width: 350px;

            p { margin-bottom: 0 }
        }
    }
}