@import "./mixins";
@import url("https://use.typekit.net/szv4dkj.css");

.wysywig {
  /**
 * Basic typography style for copy text
 */
  body {
    font-family: 'aktiv-grotesk-extended', sans-serif;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #555555;
    font-weight: 400;
    font-style: normal;
  }

  h2, h3, h4, h5, h6, p {
    font-family: 'aktiv-grotesk-extended', sans-serif;
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, .btn {
    text-transform: uppercase;
  }

  h4, h5, .btn {
    font-weight: 500;
    font-style: normal;
  }

  h3, h6 {
    font-weight: 700;
    font-style: normal;
  }

  h1 {
    font-family: "aktiv-grotesk-extended", sans-serif;
    font-size: 65px;
    line-height: 77px;
    letter-spacing: 0;
    color: #262626;
    font-weight: 800;
    font-style: normal;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.15px;
    color: #0D0D0D;
    font-weight: 400;
    font-style: normal;
  }

  h3 {
    font-size: 25px;
    letter-spacing: .13px;
    line-height: 33px;
    color: #0d0d0d;
    text-transform: unset;
    font-weight: 400;
    margin: 30px 0;
  }

  h4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
    color: #0D0D0D;
  }

  h5 {
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #171717;
  }

  h6 {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 2.4px;
    color: #262626;
    em {
      font-size: 10px;
      letter-spacing: 0.25px;
      line-height: 16px;
      color: #686868;
      font-weight: 600;
      text-transform: uppercase;
      //display: block;
    }
  }

  p {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
    color: #555555;
  }

  a {
    color: $text-color;
    text-decoration: none;
    //transition: $transition-base;
    &:hover {
      color: #A4A4A4;
    }
  }


  &--article {
    h1, h2, h3, h4, h5, h6 {
      margin: 10px 0;

      scroll-margin-top: 100px;
      //em {
      //  margin: -10px 0;
      //}
    }

    a{
      text-decoration: underline;
      em{
        color: unset;
      }
    }
    em{
      display: unset;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      padding-inline-start: 20px;
    }

    ol {
      padding-inline-start: 20px;
    }

    blockquote {
      border-left: 10px solid #ccc;
      padding: 10px 20px;
      margin: 0 0 20px;
    }
    code {
      font-family: 'Source Code Pro';
      background: #292929;
      color: #fafafa;
      font-size: 16px;
      padding: 10px;
    }

    .react-page {
      &-image-content {
        img {
          max-width: 100%;
          width: 100%;
          padding: 0 10px;
          margin: 0 -10px;
          @include respond-to('sm') {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}