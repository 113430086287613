.mirror {
  position: relative;
  svg {
    position: absolute;
    left: -60px;
  }
}

.mirrorType {
  position: relative;
  svg {
    position: absolute;
    left: -100px;
  }
}