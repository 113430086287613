.thumb-box{
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 8px;
    width: 100%;
}

.thumb-box .clear {
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    color: red;
}