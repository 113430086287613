/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}


/// Copy text color
$text-color: #555555 !default;

/// Copy white color
$color-white: #FFFFFF !default;

/// Color light white
$color-light-white: #F1F1F1 !default;

/// Color primary
$color-primary: #262626 !default;

/// Color accent
$color-accent: #D9D9D9 !default;

/// Color light
$color-light: #CCCCCC !default;

/// Transition base
$transition-base: 0.3s ease-in-out !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
        'xs': 576px,
        'sm': 768px,
        'md': 992px,
        'lg': 1200px,
        'xlCustom': 1365px,
        'xl': 1415px,
        'xxl': 1600px
) !default;